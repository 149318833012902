import React from 'react'
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Nie znaleziono strony" />
    <Container>
      <Row>
        <Col>
          <h1>NIE ZNALEZIONO STRONY</h1>
          <p>Niestety nie znaleziono podstrony o podanym adresie.</p>
          <p>
            Kliknij <Link to="/">repertuar </Link> aby zobaczyć repertuar
            naszego kina lub wybierz odpowiednią podstronę z nawigacji.
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
